import React, { useState } from "react"
import { Link } from "gatsby"

const SignUpForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState("")
  const action = "https://app.convertkit.com/forms/1698917/subscriptions"
  const emailFieldName = "email_address"

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)

    let data = new FormData()
    data.append(emailFieldName, email)

    let request = new XMLHttpRequest()
    request.open("POST", action)
    request.send(data)
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  let output = submitted ? (
    <ThankYouMessage email={email} />
  ) : (
    <div className="mt-3 mb-3">
      <form onSubmit={handleSubmit}>
        <div data-style="minimal">
          <div className="text-sm md:text-base lg:text-lg">
            <p>Sign up for occasional book updates:</p>
          </div>
          <div>
            <div className="flex">
              <input
                className="w-full max-w-sm sm:max-w-xs px-3 py-2 text-base leading-6 appearance-none shadow-none border border-r-0 border-grey-200 rounded rounded-r-none focus:shadow-none focus:border-blue-100"
                name="email_address"
                aria-label="Your email address"
                placeholder="Enter your email"
                required=""
                onChange={handleEmailChange}
                type="email"
              />
              <button className="px-6 py-2 md:px-8 text-base text-white font-semibold leading-snug relative rounded bg-orange-500 hover:bg-orange-600 focus:outline-none focus:outline-shadow rounded-l-none">
                <span>Subscribe</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )

  return output
}

const ThankYouMessage = ({ email }) => (
  <div className="text-sm md:text-base lg:text-lg">
    <p>Thank you for subscribing {email}!</p>
    <p>Please check your inbox to confirm your email address.</p>
  </div>
)

const Footer = () => (
  <footer className="px-4 sm:px-6 pt-12 pb-8 mx-auto">
    <div className="bg-gray-200 py-2 md:py-4 px-4 md:px-12 mx-auto w-full max-w-2xl lg:max-w-3xl xl:max-w-6xl border border-gray-300 rounded">
      <SignUpForm />
    </div>
    <div className="pt-8">
      <div className="block mb-8 flex text-lg lg:text-xl">
        <Link className="mx-auto underline" to="/">
          Table of Contents
        </Link>
      </div>
      <div className="block mb-6 flex text-sm lg:text-lg">
        <div className="mx-auto">
          Made with{" "}
          <span role="img" aria-label="emoji heart">
            ❤️
          </span>{" "}
          by{" "}
          <a
            className="underline"
            rel="external"
            href="https://twitter.com/germsvel"
          >
            @germsvel
          </a>
        </div>
      </div>
      <div className="block mb-4 flex text-sm lg:text-lg">
        <div className="mx-auto">
          Have feedback?{" "}
          <a
            className="underline"
            rel="external"
            href="mailto:german@germanvelasco.com"
          >
            Send me an email
          </a>
        </div>
      </div>
      <div className="block flex text-sm lg:text-md lg:text-lg">
        <div className="mx-auto">
          © {new Date().getFullYear()}, German Velasco
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
