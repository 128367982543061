/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Analytics from "./analytics"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <>
      <Analytics />
      <div className="antialiased text-gray-900">
        <div className="px-4 pt-8 max-w-2xl mx-auto sm:px-6 lg:max-w-4xl lg:px-8 xl:max-w-6xl">
          <main>{children}</main>
        </div>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
